import React, { useState, useEffect, useRef } from "react";
import { Public, CreateNewFolder, AddLocation, StopCircle, Save } from '@mui/icons-material';
import { useApolloClient } from '@apollo/react-hooks';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent, DateTimePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
    CheckBoxComponent,
    RadioButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import { gql } from "@apollo/client";
import moment from 'moment';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import { Player } from '@lottiefiles/react-lottie-player';
import { downloadFileImage } from '../../apollo/mutations';
import noImage from '../../static/img/no-img.png';
import { getLocaleDateString } from '../../utils/getLocaleDateString';
import PointDialogComponent from './pointDialogComponent';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PolygonDialogComponent from "./polygonDialogComponent";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const Input = ({ editScreen, value, inputQuery, onChange, type, ...rest }) => {
    const inputRef = useRef(null);
    const comboBoxRef = useRef(null);
    const datePickerRef = useRef(null);
    const dateRef = useRef(null);
    const client = useApolloClient();
    const userTimeZone = JSON.parse(localStorage.getItem('timezone'));
    const [gridList, setGridList] = useState('');
    const [fields, setFields] = useState({ value: '', text: '', });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cardImage, setCardImage] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);
    const markerRef = useRef(null);

    let defaultValue = null;

    if (!editScreen) {
        if (rest.column_default && rest.column_default.includes('@')) {
            defaultValue = userTimeZone;
        }
        else {
            defaultValue = rest.column_default;
        }
    }


    const _getDynamicScreen = async () => {
        setIsLoading(true);
        // setError(null);
        try {
            const { data } = await client.query({ query: gql`${inputQuery}` });
            const queryName = rest.query_name;
            setFields({ value: rest.query_key, text: rest.query_value });
            setGridList(data[queryName]);
        } catch (e) {
            setError(e);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (inputQuery !== null) {
            _getDynamicScreen()
        }
        return () => {
            setGridList('');
            setFields({ value: '', text: '', });
            setError(null);
        }
    }, [type, inputQuery])

    const localDateFormat = getLocaleDateString()

    const openDialog = (event) => {
        event.preventDefault();
        setDialogVisible(true);
    };

    const closeDialog = () => {
        setDialogVisible(false);
    };

    useEffect(() => {
        if (dialogVisible && mapContainerRef.current) {
            if (!mapRef.current) {
                setTimeout(function () {
                    mapRef.current = L.map(mapContainerRef.current).setView([51.505, -0.09], 13);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; OpenStreetMap contributors'
                    }).addTo(mapRef.current)
                    if (value) {
                        const match = value.match(/POINT\(([-\d.]+)\s([-\d.]+)\)/);
                        if (match) {
                            const [lng, lat] = [parseFloat(match[1]), parseFloat(match[2])];
                            const latLng = L.latLng(lat, lng);
                            markerRef.current = L.marker(latLng).addTo(mapRef.current);
                            mapRef.current.setView(latLng, 13);
                        }
                    }
                    if (value) {
                        const match = value.match(/POLYGON\(\(([-\d.\s,]+)\)\)/);
                        if (match) {
                            const latlngs = match[1].split(',').map(pair => {
                                const [lng, lat] = pair.trim().split(' ').map(Number);
                                return L.latLng(lat, lng);
                            });
                            const polygon = L.polygon(latlngs).addTo(mapRef.current);
                            mapRef.current.setView(latlngs[0], 8);
                        }
                    }
                }, 200);
            }
        }
    }, [dialogVisible]);

    switch (type) {
        case "textbox":
            return (
                <TextBoxComponent
                    ref={inputRef}
                    onClick={() => {
                        inputRef.current?.element.focus()
                    }}
                    placeholder={rest?.placeholder}
                    change={({ value }) => {
                        onChange(value)
                    }}
                    value={value || defaultValue}
                />

            );
        case "number":
            return (
                <NumericTextBoxComponent
                    placeholder={rest?.placeholder}
                    change={({ value }) => onChange(value)}
                    value={value || defaultValue}
                />
            );
        case "radio":
            return rest?.options.map((e) => (
                <RadioButtonComponent
                    key={e}
                    label={e}
                    value={e}
                    onChange={(value) => onChange(value)}
                    checked={value === e}
                />
            ));
        case "dropdown":
            const [dropBoxValue, setDropBoxValue] = useState(value || defaultValue);
            if (isLoading || !gridList) {
                return (
                    <Player
                        autoplay
                        loop
                        src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
                        style={{ height: '60px', width: '60px' }}
                    />
                );
            }
            if (error) {
                return <div>Error: {error.message}</div>;
            }
            return (
                <ComboBoxComponent
                    ref={comboBoxRef}
                    onClick={() => {
                        comboBoxRef.current?.element.focus();
                    }}
                    dataSource={gridList}
                    fields={fields}
                    allowFiltering={true}
                    filtering={(e) => {
                        if (e.text === '') {
                            e.updateData(gridList); // Show full list if input is empty
                        } else {
                            let filteredData = gridList.filter((item) =>
                                item[fields.text].toLowerCase().indexOf(e.text.toLowerCase()) !== -1
                            );
                            e.updateData(filteredData); // Update ComboBox with filtered data
                        }
                    }}
                    select={({ itemData }) => {
                        setDropBoxValue(itemData)
                        onChange(itemData[fields.value]);
                    }}
                    change={(e) => {
                        // When user selects or presses Tab, check for validity
                        if (e.itemData) {
                            let filteredData = gridList.filter((item) =>
                                item[fields.text].toLowerCase().indexOf(e.itemData[fields.text].toLowerCase()) !== -1
                            );
                            // If no match found in the filtered data
                            if (filteredData.length === 0) {
                                onChange(null); // Set to null if invalid
                            }
                        } else {
                            onChange(null); // Set to null if e.itemData is null
                        }
                    }}
                    blur={(e) => {
                        // Handle ComboBox blur event to check if the input is valid when focus is lost (like on Tab press)
                        let inputValue = comboBoxRef.current?.element.value.toLowerCase(); // Get the typed input value
                        let validItem = gridList.find((item) =>
                            item[fields.text].toLowerCase().startsWith(inputValue)
                        );
                        if (validItem) {
                            // If a partial match is found, set the ComboBox value to the matching item
                            setDropBoxValue(validItem);
                            onChange(validItem[fields.value]);
                            comboBoxRef.current.value = validItem[fields.text]; // Set the input value to the matched item
                        } else {
                            // If no match is found, reset the ComboBox
                            onChange(null);
                            comboBoxRef.current.value = null; // Clear the input field
                        }
                    }}
                    value={value || defaultValue}

                />
            );

        case "checkbox":
            const handleCheckboxClick = () => {
                if (value === true) {
                    onChange(false); // Go to unchecked
                } else if (value === false) {
                    onChange(null); // Go to indeterminate
                } else {
                    onChange(true); // Go to checked
                }
            };

            const isChecked = defaultValue !== null ? Boolean(defaultValue) : value;
            const isIndeterminate = (value === null || value === '') && defaultValue === null


            return (
                <CheckBoxComponent
                    label={rest?.checkboxLabel}
                    onClick={handleCheckboxClick}
                    name={rest?.name}
                    checked={isChecked === true}
                    indeterminate={isIndeterminate}
                />
            );

        case "datetime":

            return (
                <DateTimePickerComponent
                    ref={datePickerRef}
                    onClick={() => {
                        datePickerRef.current?.element.focus();
                    }}
                    format={localDateFormat + ', HH:mm:ss'}
                    id="datepicker"
                    change={({ value }) => {
                        // const formattedValue = value ? moment(value).format('DD/MM/YYYY') : null;
                        // const currentFormat = localDateFormat.toUpperCase() + ' hh:mm a'
                        // const formattedValue = value ? moment(value).format(currentFormat) : null;
                        onChange(value);
                    }}
                    placeholder="Enter date"
                    value={value || defaultValue}
                >
                </DateTimePickerComponent>
            );
        case "date":
            const dateValue = value ? new Date(value) : defaultValue;
            return (
                <DatePickerComponent
                    ref={dateRef}
                    onClick={() => {
                        dateRef.current?.element.focus();
                    }}
                    format={localDateFormat}
                    id="date"
                    change={({ value }) => {
                        onChange(value);
                    }}
                    placeholder="Enter date"
                    value={dateValue}
                >
                </DatePickerComponent>
            );
        case "geometry":
            return null

        case "image":
            const getAnimalsImage = async (imgId) => {
                const { data } = await client.mutate({ mutation: downloadFileImage, variables: { files_uid: imgId } });
                setCardImage(data.fileDownload.file_content)
            }
            if (value) {
                getAnimalsImage(value)
            }
            return (
                <img
                    style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '5px', marginRight: '16px' }}
                    alt='Image of pet'
                    width='160px'
                    height='150px'
                    src={`data:image/jpeg;base64,${cardImage}`}
                    onError={(e) => {
                        e.target.onerror = null; // prevent infinite loop
                        e.target.src = noImage;
                    }}
                />
            )

        case "point":
            const handleUpdateValue = (newValue) => {
                setDialogVisible(false);
                onChange(newValue); // Update the parent component's value
            };
            return (
                <div className="input-group geometry-group">
                    <TextBoxComponent
                        placeholder={rest?.placeholder}
                        readonly={true}
                        change={({ value }) => {
                            onChange(value)
                        }}
                        value={value || defaultValue}
                    />
                    <ButtonComponent cssClass="geometry-btn" onClick={(event) => openDialog(event)}>
                        <Public />
                    </ButtonComponent>
                    <PointDialogComponent dialogVisible={dialogVisible} closeDialog={closeDialog} value={value} onUpdateValue={handleUpdateValue} />
                </div>
            );

        case "polygon": {
            const handleUpdateValue = (newValue) => {
                setDialogVisible(false);
                onChange(newValue); // Update the parent component's value
            };
            return (
                <div className="input-group geometry-group">
                    <TextBoxComponent
                        placeholder={rest?.placeholder}
                        readonly={true}
                        change={({ value }) => {
                            onChange(value)
                        }}
                        cssClass="geometry-textbox"
                        value={value || defaultValue}
                    />
                    <ButtonComponent cssClass="geometry-btn" onClick={(event) => openDialog(event)}>
                        <Public />
                    </ButtonComponent>
                    <PolygonDialogComponent dialogVisible={dialogVisible} closeDialog={closeDialog} value={value} onUpdateValue={handleUpdateValue} />
                </div>
            )
        }

        default:
            return null;
    }
};

export default Input;


