import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Sidebar from '../../components/Sidebar';

import { getDatasource, infiniteData } from './data';
// custom code end
function InfiniteScrolling() {
    // custom code start
    const SAMPLE_CSS = `
    .image {
        position: absolute;
        background-repeat: no-repeat;
        background-image: url('https://ej2.syncfusion.com/react/demos/src/grid/images/spinner.gif');
        background-position: center;
        width: 16px;
        height: 28px;
    }

    .e-bigger .image {
        height: 36px;
    }
    
    #popup {
        position: absolute;
        background-color: transparent;
        display: none;
        z-index: 100;
    }
    .div-button{
        margin: 5px 5px 5px 0;
    }`;
    let grid;
    let data = [];
    function onclick() {
        if (!data.length) {
            getDatasource();
            grid.dataSource = data = infiniteData;
        }
    }
    return (
        <Sidebar title={'Index'}>
            <div className='control-pane' style={{height: '80vh', margin: '60px 20px'}}>
                <div className='control-section'>
                    <style>
                        {SAMPLE_CSS}
                    </style>
                    <div className='div-button'>
                        <ButtonComponent cssClass={'e-info'} onClick={onclick.bind(this)}>Load 100K Data</ButtonComponent>
                        <span id="popup">
                            <span id="gif" className="image"></span>
                        </span>
                    </div>
                    <GridComponent dataSource={[]} height={600} pageSettings={{ pageSize: 50 }} ref={g => grid = g}>
                        <ColumnsDirective>
                            <ColumnDirective field='FIELD1' headerText='Player Name' width='130'></ColumnDirective>
                            <ColumnDirective field='FIELD2' headerText='Year' width='100'></ColumnDirective>
                            <ColumnDirective field='FIELD3' headerText='Stint' width='120'></ColumnDirective>
                            <ColumnDirective field='FIELD4' headerText='TMID' width='120'></ColumnDirective>
                            <ColumnDirective field='FIELD5' headerText='LGID' width='120'></ColumnDirective>
                            <ColumnDirective field='FIELD6' headerText='GP' width='120'></ColumnDirective>
                            <ColumnDirective field='FIELD7' headerText='GS' width='120'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[]} />
                    </GridComponent>
                </div>
            </div>
        </Sidebar>
    );
}
export default InfiniteScrolling;