import React, { useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css'; // Import the styles for marker clustering
import { GET_POLYGONS_FOR_MAP_LIST } from '../../../../apollo/queries';


const New = ({ panel, panelJSON, controls, animalsData }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const mapRef = useRef(null);
    const { loading, error, data } = useQuery(GET_POLYGONS_FOR_MAP_LIST, {
        variables: {
            locale: 'en',
            v_sys_panels_maps_guid: panel.sys_panels_guid,
            v_tenants_guid: user.tenant.tenants_guid
        },
    });


    useEffect(() => {
        let center = [];
        let zoomLevel = ''
        if (panelJSON && Object.keys(panelJSON?.map_definition).length !== 0) {
            center = [panelJSON?.map_definition?.centroid?.coordinates[1], panelJSON?.map_definition?.centroid?.coordinates[0]] || [51.505, -0.09];
            zoomLevel = panelJSON?.map_definition?.zoom || 13;
        }
        const map = L.map(mapRef.current).setView(center, zoomLevel);

        // Add a tile layer to the map
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors',
        }).addTo(map);

        // Create a marker cluster group
        const markers = L.markerClusterGroup();
        const polygons = L.layerGroup();

        function findObjectBySysColumnsGuid(array, sysColumnsGuid) {
            return array.find(item => item.sys_columns_guid === sysColumnsGuid);
        }

        const getPopupContent = (animal) => {
            let popupContent = '';
            // Iterate over the properties of the animal object
            for (const [key, value] of Object.entries(animal)) {
                if (value && key !== '__typename') {
                    popupContent += `<b>${key}:</b> ${value}<br>`;
                }
            }

            return popupContent;
        };

        animalsData && animalsData.forEach((animal) => {

            const pins = panelJSON && panelJSON.map_definition.pins

            if (pins) {
                pins.forEach((pin) => {
                    const pinFound = findObjectBySysColumnsGuid(controls, pin.pin.sys_columns_guid);

                    if (pinFound) {

                        const markerHtmlStyles = `
                        background-color: rgb${pin.pin.colour};
                        width: 24px;
                        height: 24px;
                        display: block;
                        left: -8px;
                        top: -8px;
                        position: relative;
                        border-radius: 24px 24px 0;
                        transform: rotate(45deg);
                        border: 1px solid #FFFFFF`

                        const icon = L.divIcon({
                            className: "my-custom-pin",
                            iconAnchor: [0, 24],
                            labelAnchor: [-6, 0],
                            popupAnchor: [0, -36],
                            html: `<span style="${markerHtmlStyles}" />`
                        })
                        const marker = L.marker([animal[pinFound.name].coordinates[1], animal[pinFound.name].coordinates[0]], { icon: icon }).bindPopup(getPopupContent(animal));
                        markers.addLayer(marker);
                    }
                })
            }

        });

        const addPolygonsToMap = (polygonsData) => {

            polygonsData.forEach((region) => {
                function processCoordinates(coords) {
                    if (Array.isArray(coords)) {
                        if(typeof coords[0] === 'number' || typeof coords[0] === 'string') {
                            return [coords?.[1], coords?.[0]]
                        }
                        return coords.map(processCoordinates);
                    }
                }
                const regionCoords = region?.regions_definition?.coordinates.map(processCoordinates);
                const polygonObject = L.polygon(regionCoords, { color: 'blue', fillOpacity: 0.5 }); // Customize the color and other options

                // You can bind popups or other properties to the polygonObject if needed
                polygonObject.bindPopup(`<b>${region.regions_name}</b>: ${region.regions_types_name || 'No type'}`);

                polygons.addLayer(polygonObject);
            });
        };

        if (data && data.dal_get_polygons_for_map_list) {
            addPolygonsToMap(data.dal_get_polygons_for_map_list);
        }

        // Add the marker cluster group to the map
        map.addLayer(markers);
        map.addLayer(polygons);

        // Clean up when the component is unmounted
        return () => {
            map.remove();
        };
    }, [panel, controls, panelJSON, animalsData, data]); // The empty dependency array ensures that this effect runs once after the initial render

    return <div ref={mapRef} style={{ height: '100%' }} />;
};

export default New;