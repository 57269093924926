import React, { useState, useEffect, useContext, memo, useRef } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { GridComponent as GridComponentEJ2, ColumnsDirective, ColumnDirective, Page, Inject, Filter, Sort, InfiniteScroll, VirtualScroll } from '@syncfusion/ej2-react-grids';
import { gql } from "@apollo/client";
import { SelectedRowContext } from '../../context/SelectedRowContext';
import { Tooltip } from '@syncfusion/ej2-react-popups';
import moment from 'moment';
import styled from 'styled-components';
import { GlobalTheme } from '../../context/GlobalTheme';
import { SidebarContext } from './Main/context';
import { Player } from '@lottiefiles/react-lottie-player';
import { useSelector, useDispatch } from 'react-redux';
import { getLocaleDateString } from '../../utils/getLocaleDateString';
import TablePagination from '@mui/material/TablePagination';

const Wrapper = styled('div')`
  & #Grid_content_table {
    background: ${props => props.bg};
  }
  
  & td {
    color: ${props => props.textColor} !important;
  }
`;

const GridComponent = styled(GridComponentEJ2)`
  & .e-content {
    background: ${props => props.bg} !important;
  }
`;

const GridPanel = memo(({ panel, panelJSON, gridQuery, dynamicGridHeight, rowCountQuery }) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const [gridList, setGridList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1000);
    const { selectedRow, changeSelectedRow } = useContext(SelectedRowContext);
    const sidebarContext = useContext(SidebarContext);
    const refreshedPanelsGuid = useSelector(state => state.mainReducer.refreshedPanelsGuid);
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);
    const screenRefreshTime = useSelector(state => state.mainReducer.screenRefreshTime);

    const isValidDateTime = (value) => {
        return typeof value === 'string' && !/^\d+$/.test(value) && moment(value, moment.ISO_8601, true).isValid();
    };

    const isDateOnly = (dateString) => {
        const date = moment(dateString, moment.ISO_8601, true);
        return date.isValid() && date.format('HH:mm:ss') === '00:00:00' && dateString.length === 10;
    };

    const formatData = (data) => {
        return data.map(item => {
            const newItem = { ...item };
            Object.keys(newItem).forEach(key => {
                if (isValidDateTime(newItem[key])) {
                    newItem[key] = new Date(newItem[key]);
                }
            });
            return newItem;
        });
    };


    // Sanitize the data to replace null values with the placeholder
    const sanitizeData = (data) => {
        return data.map(item => {
            const sanitizedItem = { ...item };
            Object.keys(sanitizedItem).forEach(key => {
                if (sanitizedItem[key] === null) {
                    sanitizedItem[key] = '';
                }
            });
            return sanitizedItem;
        });
    };


    const _fetchData = async (page, pageSize) => {
        setIsLoading(true);
        const offset = page * pageSize;
        if (!gridQuery) {
            dispatch({ type: 'SAVE_REQUIRED_STATUS_SCREEN', data: true });
            setIsLoading(false);
        }
        const { data } = await client.query({ query: gql`${gridQuery}`, variables: { limit: pageSize, offset } });
        let rowCount = await client.query({ query: gql`${rowCountQuery}`, variables: { limit: pageSize, offset } });
        const queryName = panelJSON.query_name;
        setTotalRecords(rowCount.data?.[`${queryName}_aggregate`]?.aggregate?.totalCount)
        const formattedList = formatData(data?.[queryName]);
        const sanitizedList = sanitizeData(formattedList);
        setGridList(sanitizedList);
        setIsLoading(false);
    };


    useEffect(() => {
        if (panel.sys_panels_guid === refreshedPanelsGuid) {
            _fetchData(currentPage, rowsPerPage);
        }

        return () => {
            changeSelectedRow(null);
        };

    }, [isRefreshButtonClicked]);

    useEffect(() => {
        let interval;
        if (panel.auto_refresh) {
            interval = setInterval(() => _fetchData(), 1000 * screenRefreshTime)
        }
        _fetchData(currentPage, rowsPerPage);
        return () => {
            changeSelectedRow(null);
            clearInterval(interval)
        };
    }, [panelJSON, sidebarContext?.onSubmit, currentPage, rowsPerPage])

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(0); // Reset to the first page
    };

    function rowSelected(g) {
        changeSelectedRow(g.data);
    }

    const globalTheme = useContext(GlobalTheme);


    const gridRef = useRef(null);

    const handleActionComplete = (args) => {
            setTimeout(() => {
                const inputElement = args.filterModel.dlgDiv.querySelector('.e-flmenu-input.e-control.e-autocomplete');
                if (inputElement) {
                    inputElement.focus();
                } else {
                    console.error('Input element not found');
                }
            }, 100); // Adjust the delay as needed
    };

    return (
        <Wrapper
            className='control-section'
            bg={globalTheme[globalTheme.activeTheme]?.background}
            textColor={globalTheme[globalTheme.activeTheme]?.color}
            style={{ height: 'calc(100% - 49px)', width: '100%' }}
        >
            {
                isLoading ?
                    <div style={{ height: 'calc(100% - 49px)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Player
                            autoplay
                            loop
                            src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
                            style={{ height: '20vh', width: '20vw' }}
                        />
                    </div>
                    :
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <GridComponent
                            ref={gridRef}
                            bg={globalTheme[globalTheme.activeTheme]?.background}
                            id="Grid"
                            height={panel.panel_layout_json.sizeY > 6 ? panel.panel_layout_json.sizeY * (dynamicGridHeight - 2) : panel.panel_layout_json.sizeY * (dynamicGridHeight - 12)}
                            enableVirtualization={true}
                            enableColumnVirtualiztion={true}
                            dataSource={gridList}
                            allowFiltering={true}
                            allowSorting={true}
                            filterSettings={{
                                type: 'Menu',
                            }}
                            rowSelected={rowSelected}
                            actionComplete={handleActionComplete}
                        >
                            <ColumnsDirective>
                                {panelJSON && panelJSON.controls.map((control, index) => {
                                    return (

                                        !control.hidden ?
                                            <ColumnDirective
                                                key={index}
                                                field={control.name}
                                                headerText={control.label}
                                                clipMode='EllipsisWithTooltip'
                                                width='150'
                                                type={
                                                    control.type === 'datetime' ? 'datetime' :
                                                        control.type === 'date' ? 'date' : undefined
                                                }
                                                format={control.type === 'datetime' ? getLocaleDateString() + ', HH:mm:ss' :
                                                    control.type === 'date' ? getLocaleDateString() : undefined
                                                }
                                            ></ColumnDirective> : null
                                    )
                                }

                                )}
                            </ColumnsDirective>
                            <Inject services={[Tooltip, Filter, Sort, VirtualScroll]} />
                        </GridComponent>
                        <TablePagination
                            component="div"
                            count={totalRecords || 10}
                            page={currentPage}
                            onPageChange={handlePageChange}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000, 10000]}
                        />

                    </div>
            }
        </Wrapper>
    );
});

export default GridPanel;
